<template>
<div class="order-follow">
  <div v-if="orderDetail.id" class="order-info">
    <deliver-item :dataSource="orderDetail" />
  </div>
  <van-collapse v-model="activeNames" style="padding: 16px;" :right-icon="false">
    <van-collapse-item class="form-collapse-item special-collapse-item" disabled name="1">
      <template #title class="">
        <div>{{$t('跟进信息')}}</div>
      </template>
      <van-form ref="followInfo" input-align="right" error-message-align="right" class="followInfo">
        <van-field
          name="radio"
          :label="$t('跟进方式')"
          required
          input-align="right"
          error-message-align="right"
          :rules="[{required:true, message:$t('请选择跟进方式')}]">
          <template #input>
            <van-radio-group
              v-model="followRequest.followMode"
              icon-size="32px"
              class="custom-radio-group-wrap"
              >
              <van-radio v-for="item in followModeList" :key="item.code"  :name="item.code" class="custom-radio-wrap">
                <template #icon="props">
                  <div class="custom-radio" :class="{ checked: props.checked, wechatCheck: props.checked }">{{item.name}}</div>
                </template>
              </van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <!-- <van-field
          v-if="followRequest.followMode === '1010003'"
          :label="$t('关联进店客户')"
          right-icon="arrow"
          readonly
          @click="openRoom">
          <template #input>
            <span style="color: #b9921a">{{ roomSuccess }}</span>
          </template>
        </van-field> -->
        <van-cell :title="$t('跟进记录')" required>
          <template #right-icon>
            <btn-voice-input @input="(value) => (followRequest.followRemark = followRequest.followRemark + value.slice(0, 200))" />
          </template>
        </van-cell>
        <van-field
          v-model.trim="followRequest.followRemark"
          rows="3"
          autosize
          type="textarea"
          maxlength="200"
          :placeholder="$t('请输入不少于10个字的跟进记录')"
          show-word-limit
          :error-message="followRemarkErrMsg"
          :rules="[{required:true}, { validator: followRemarkValidator }]"
        />
      </van-form>
    </van-collapse-item>
  </van-collapse>
  <div class="common-footer">
    <van-button class="btn back" @click="goBack">{{$t('返回')}}</van-button>
    <van-button class="btn submit" @click="onSubmit">{{$t('提交')}}</van-button>
  </div>
  <Room v-if="roomDialogShow" @close="closeRoomDialog"></Room>
</div>
</template>
<script>
import BtnVoiceInput from '@/components/btn-voice-input'
import deliveryServices from '@/services/deliveryServices'
import orderServices from '@/services/orderServices'
import DeliverItem from './deliver-item.vue'
import loading from '@/utils/loading'
import Room from '@/components/room'
export default {
  name: 'orderFollow',
  components:{ BtnVoiceInput, DeliverItem, Room },
  data(){
    return {
      orderDetail: {},
      activeNames: ['1'],
      followRequest:{
        followMode: '',
        followRemark: '',
        orderId: ''
      },
      roomDialogShow: false,
      roomSuccess: '',
      followRemarkErrMsg: ''
    }
  },
  computed:{
    // 跟进方式
    followModeList() {
      let list = this.$store.getters.getDictHash(1010)
      // 排除店内接待
      // list = list.filter(({ code })=> !(['1010003'].includes(code)))
      return list
    },
  },
  mounted() {
    if (this.$route.query.taskCode) { // 交付回访
      this.getDetail()
    }
    const { orderId, followMode } = this.$route.query
    this.followRequest.orderId = orderId
    this.followRequest.followMode = followMode
  },
  methods:{
    // 返回
    goBack() {
      this.$router.go(-1)
    },
    // 提交
    async onSubmit() {
      // 输入内容校验
      try {
        await this.$refs.followInfo.validate()
        loading.showLoading()
        await orderServices.orderFollow(this.followRequest)
        loading.hideLoading()
        this.$router.go(-1)
      } catch (error) {
        console.log('clue incubation follow:',error)
      }
    },
    // 获取订单详情
    async getDetail() {
      loading.showLoading()
      const res = await deliveryServices.deliveryDetail({ id: this.$route.query.orderId })
      loading.hideLoading()
      this.orderDetail = res
    },
    // 打开第三方的页面
    openRoom() {
      this.roomDialogShow = true
    },
    closeRoomDialog(success) {
      this.roomDialogShow = false
      if (success === 'success') {
        this.roomSuccess = this.$t('认领成功')
      }
    },
    followRemarkValidator(value) {
      if (value.length < 10) {
        this.followRemarkErrMsg = this.$t('请输入不少于10个字的跟进记录')
        return false
      }
      this.followRemarkErrMsg = ''
      return true
    }
  }
}
</script>
<style lang="less" scoped>
@import "../../clue/style/commonless.less";
.order-info {
  padding: 16px 16px 0 16px;
}
.order-follow{
  /deep/ .custom-radio-group-wrap {
    margin-top: -10px;
    display: flex;
    flex-wrap: wrap;
    .custom-radio-wrap {
      margin-top: 10px;
      margin-left: 12px;
      .custom-radio {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 8px;
        height: 24px;
        color: rgba(13,23,26,0.45);
        background-color: #F5F5F5;
        border-radius: 3px;
        font-size: 12px;
        // &.checked {
        //   // border: 1px solid @yellow;
        //   // color: @yellow-text
        // }
        &.wechatCheck{
          color: #316EE2;
          background: #F2F3FF;
        }
        &.appointmentTypeCheck{
          color: #029640;
          background: rgba(2,150,64,0.1);
        }
        &.typeCheck{
          color: #0082EF;
          background: rgba(0,130,239,0.1);
        }
        &.commonCheck{
          color: #B9921A;
          background: rgba(238,212,132,0.45);
        }
        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
          background-color: #ebedf0;
          border-color: #c8c9cc
        }
      }
    }
  }

  /deep/ .special-collapse-item{
    .van-collapse-item__title--disabled{
      .van-icon-arrow{
        display: none;
      }
    }
    .van-cell__title{
      display: flex;
      .icon{
        flex: 1;
        cursor: pointer;
        text-align: right;
        margin-left: 10px;
        font-size: 14px;
        color: #0040C5;
      }
    }
  }

}
</style>
